import axios from "axios";
import { AuthenticationService } from "../../services";
import LocalStorageService from "../../services/LocalStorageService";
import dotenv from "dotenv";

dotenv.config();

const access_token = LocalStorageService.getAccessToken();

function authHeader() {
  if (access_token) {
    return {
      baseURL: `${BASE_URL}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    };
  } else {
    return {
      baseURL: `${BASE_URL}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
  }
}

const {
  NODE_ENV,
  REACT_APP_ADMIN_DEVELOPMENT_URL,
  REACT_APP_ADMIN_PRODUCTION_URL,
} = process.env;

const BASE_URL = 
!NODE_ENV || NODE_ENV === 'development'? 
REACT_APP_ADMIN_DEVELOPMENT_URL : REACT_APP_ADMIN_PRODUCTION_URL;

const instance = axios.create(authHeader());

export default instance;
