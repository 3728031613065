import { AuthHeader, HandleResponse } from "../utils/_helpers";
import axios from "../utils/axios/axios";
import { AuthenticationService } from "./Authentication.service";

export const UserService = {
  getAll,
  countRegisteredUsers,
  dailyUsers,
  getAllUsers,
  getCurrentUser,
  allAdminUsers,
  searchUsers,
  countRegisteredUserState,
};

function getAll() {
  const requestOptions = { method: "GET", headers: AuthHeader() };
  return fetch(`/users`, requestOptions).then(HandleResponse);
}
async function getAllUsers(url) {
  return axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (error?.message==="Network Error") {
        AuthenticationService.logout();
       }
      if (error.response.data.message === "Token Absent") {
        window.location.reload(false);

      } else if (error.response.data.message === "Token Expired") {
        console.log(error.response.data.message);
        AuthenticationService.refreshToken().then((user) => {
          countRegisteredUsers();
        });
      } else {
        AuthenticationService.logout();
      }
    });
}

async function countRegisteredUsers() {
  return axios
    .get(
      "admin/count-registered-users"
    )
    .then((res) => {
      console.log(res, "countRegistered users");
      const data1 = res.data.data;
      return data1;
    })
    .catch((error) => {
      console.log(error,"Peter")
      if (error.response.data.message === "Token Absent") {
        window.location.reload(false);
      } else if (error.response.data.message === "Token Expired") {
        console.log(error.response.data.message);
        AuthenticationService.refreshToken().then(() => {
          countRegisteredUsers();
        });
      } else {
        AuthenticationService.logout();
      }
    });
}
async function countRegisteredUserState(url) {
  return axios
    .get(url)
    .then((res) => {
      console.log(res, "countRegistered users");
      const data1 = res.data.data;
      return data1;
    })
    .catch((error) => {
      if (error.response.data.message === "Token Absent") {
        console.log(error,error.response.data.message)
        window.location.reload(false);
      } if (error.response.data.message == "Token Expired") {
        console.log(error.response.data.message);
        AuthenticationService.refreshToken().then(() => {
          countRegisteredUsers();
        });
      } 
        // AuthenticationService.logout();
      
    });
}

async function dailyUsers(url) {
  return axios
    .get(url)
    .then((res) => {
      const d_users = res.data.data;
      return d_users;
    })
    .catch((error) => {
        // alert(JSON.stringify(error?.response.data.message))
        // console.log(error,"Peter")
      if (error?.message==="Network Error") {
        AuthenticationService.logout();
       }
      if (error.response.data.message === "Token Absent") {
        window.location.reload(false);
      } 
      if (error.response.data.message === "Token Expired") {
        console.log(error.response.data.message);
        AuthenticationService.refreshToken().then((user) => {
          countRegisteredUsers();
        });
      }
      
        // AuthenticationService.logout();
      
    });
}
async function getCurrentUser() {
  return axios
    .get("admin/current-user")
    .then((res) => res.data)
    .catch((error) => {
      if (error.response.data.message === "Token Absent") {
        window.location.reload(false);
      } else if (error.response.data.message === "Token Expired") {
        console.log(error.response.data.message);

        AuthenticationService.refreshToken().then((user) => {
          countRegisteredUsers();
        });
      } else {
        AuthenticationService.logout();
      }
    });
}
async function allAdminUsers() {
  return axios
    .get("admin/users")
    .then((res) => {
      const data1 = res.data;
      return data1;
    })
    .catch((error) => {
      alert(JSON.stringify(error))
      if (error?.message==="Network Error") {
        AuthenticationService.logout();
       }
      if (error.response.data.message === "Token Absent") {
        window.location.reload(false);
      } else if (error.response.data.message === "Token Expired") {
        AuthenticationService.refreshToken().then((user) => {
          countRegisteredUsers();
        });
      }
    });
}
async function searchUsers(url) {
  return axios
    .get(url)
    .then((res) => {
      const users = res.data;
      return users;
    })
    .catch((error) => {
      if (error.response.data.message === "Token Absent") {
        window.location.reload(false);
      } else if (error.response.data.message === "Token Expired") {
        console.log(error.response.data.message);
        AuthenticationService.refreshToken().then((user) => {
          countRegisteredUsers();
        });
      }
    });
}
