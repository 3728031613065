import { BehaviorSubject } from "rxjs";
// import Emitter from "../../services/emitter";
import axios from "../utils/axios/axios";

import { HandleResponse } from "../utils/_helpers";

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser"))
);

export const AuthenticationService = {
  login,
  logout,
  refreshToken,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
};

async function login(email, password, props) {
  const response = await axios
    .post("admin/login", { email, password });
  const user = await HandleResponse(response);
  // store user details and jwt token in local storage to keep user logged in between page refreshes
  localStorage.setItem("currentUser", JSON.stringify(user));
  currentUserSubject.next(user);
  const { from } = props.location.state || {
    from: { pathname: "/" },
  };
  props.history.push(from);
  return user;
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("currentUser");
  currentUserSubject.next(null);
}

function refreshToken() {
  axios
    .get("admin/refresh-token")
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      // console.log({ user });
      localStorage.setItem("currentUser", JSON.stringify(user.data));
      currentUserSubject.next(user.data);
      return user;
    })
    .catch((error) => {
      console.log(error);
      console.log(error.response);
      if (
        error.response.data.message ===
        "Token has already been refreshed and invalidated"
      ) {
        AuthenticationService.logout();
        window.location.reload(false);
      }
    });
}
