import { AuthenticationService } from "../../services";

export function HandleResponse1(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if ([401, 403].indexOf(response.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        AuthenticationService.logout();
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    console.log(data);

    return data.data;
  });
}


export function HandleResponse(response) {
  try {
    console.log(response);
    if (response.data.status === "success") {
      localStorage.setItem("currentUser", JSON.stringify(response.data));

      return response.data;
    } else if (response.data.status === "failed") {
      console.log("failed", response );
      AuthenticationService.logout();
      return response.data;
    } else {
      console.log("else", response);
      AuthenticationService.logout();
    }
  } catch (error) {
    console.log("fsojgsijgiosjgisfig", error);

    if (error?.message==="Network Error") {
     }
  }
}
